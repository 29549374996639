<template>
  <div class="app-wrap">
    <el-container class="app-container">
      <el-header height="auto">
        <div class="fr">
          <el-button
            type="primary"
            :icon="CirclePlus"
            @click="editSite()"
          >添加网点</el-button>
        </div>
      </el-header>
      <el-main>
        <el-table :data="tableData">
          <el-table-column label="序号" type="index" align="right"></el-table-column>
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="手机号" align="center">
            <template #default="{ row }">
              <el-link
                v-if="row.mobile"
                type="primary"
                :icon="Phone"
                :underline="false"
                :href="`tel:${row.mobile}`"
              >{{ row.mobile }}</el-link>
            </template>
          </el-table-column>
          <el-table-column label="地区" prop="province"></el-table-column>
          <el-table-column label="操作" align="center" width="118">
            <template #default="{ row }">
              <el-button
                type="text"
                :icon="Edit"
                @click="editSite(row.id)"
              >编辑</el-button>
              <el-button
                type="text"
                class="color-danger"
                :icon="Delete"
                @click="remove(row)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer height="auto">
        <tj-pagination
          v-model="page"
          @change="getList"
        ></tj-pagination>
      </el-footer>
    </el-container>
  </div>
  <EditDialog ref="editDialog" @submit="getList"></EditDialog>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { ElMessageBox } from 'element-plus';
import {
  CirclePlus,
  Edit,
  Delete,
  Phone,
} from '@element-plus/icons-vue';
import { usePagination } from '@tj/ui';
import EditDialog from './edit.vue';
import request from '@/hooks/request';

const { page } = usePagination();
const tableData = ref([]);

function getList() {
  request.get('/admin/site/serveSite', {
    params: {
      page: page.page,
      per_page: page.limit,
    },
  }).then((data) => {
    tableData.value = data.list;
    page.total = data.total;
  });
}

const editDialog = ref();

function editSite(siteId) {
  editDialog.value.open(siteId);
}

function remove(site) {
  ElMessageBox.confirm(`删除网点：${site.name}，是否继续？`, '提示', {
    type: 'warning',
    confirmButtonText: '确定',
    cancelButtonText: '取消',
  }).then(() => {
    request.delete(`/admin/goods/brand/${site.id}`).then(() => {
      getList();
    });
  });
}

onMounted(() => {
  getList();
});
</script>
