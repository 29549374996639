<template>
  <div class="tj-map">
    <el-autocomplete
      v-model.trim="searchText"
      class="wf"
      value-key="name"
      placeholder="地址"
      style="margin-bottom: 10px;"
      :prefix-icon="Search"
      :fetch-suggestions="querySearchAsync"
      @select="selectHandler"
    ></el-autocomplete>
    <div ref="tmap" style="width: 100%; height: 500px"></div>
  </div>
</template>

<script setup>
import { extend } from 'lodash';
import {
  ref,
  computed,
  onMounted,
  nextTick,
} from 'vue';
import { Search } from '@element-plus/icons-vue';

const emit = defineEmits(['change']);
const result = {
  address: '',
  lat: 0,
  lng: 0,
  city: '',
  province: '',
  area: '',
};

function change(obj) {
  extend(result, obj);
  emit('change', result);
}

const address = ref('');
const searchText = computed({
  get() {
    return address.value;
  },
  set(val) {
    address.value = val;
    change({ address: val });
  },
});
const tmap = ref();
let map = null;
const geolocation = new qq.maps.Geolocation('HERBZ-S6M3K-2XYJ4-AF6O7-V4P4F-M7FWD', 'myapp');
const geocoder = new qq.maps.Geocoder({
  complete({
    detail: {
      // address,
      location: {
        lat,
        lng,
      },
      addressComponents: {
        city,
        district: area,
        province,
      },
    },
  }) {
    // searchText.value = address;
    const re = {
      // address: searchText.value,
      lat,
      lng,
      city,
      province,
      area,
    };
    change(re);
  },
});
let searchCallback = null;
const searchService = new qq.maps.SearchService({
  complete({ detail: { pois } }) {
    if (pois && pois.length) {
      if (searchCallback) {
        searchCallback(pois);
      }
    }
  },
});
let mark = new qq.maps.Marker({ map });
function postAddressByLatLng(latLng) {
  map.setCenter(latLng);
  mark.setMap(null);
  mark = null;
  mark = new qq.maps.Marker({
    map,
    position: latLng,
  });
  geocoder.getAddress(latLng);
}
function querySearchAsync(queryString, callback) {
  if (queryString) {
    searchCallback = callback;
    searchService.search(queryString);
  } else {
    callback([]);
  }
}
function selectHandler(item) {
  postAddressByLatLng(item.latLng);
}
onMounted(() => {
  nextTick(() => {
    map = new qq.maps.Map(tmap.value, {
      zoom: 16,
    });
    qq.maps.event.addListener(map, 'click', (event) => {
      postAddressByLatLng(event.latLng);
    });
  });
});

function init(lat, lng) {
  if (lat && lng) {
    const latLng = new qq.maps.LatLng(lat, lng);
    postAddressByLatLng(latLng);
  } else {
    geolocation.getIpLocation((detail) => {
      map.setCenter(new qq.maps.LatLng(detail.lat, detail.lng));
    });
  }
}
defineExpose({
  name: 'TjMap',
  init,
});
</script>
