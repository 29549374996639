<template>
  <tj-dialog
    v-model="visible"
    :title="title"
    @close="onClose"
    @opened="onOpened"
  >
    <el-form ref="formRef" label-width="auto" :model="form" :rules="rules">
      <el-form-item label="网点名称：" prop="name">
        <el-input v-model.trim="form.name"></el-input>
      </el-form-item>
      <el-form-item label="联系方式：" prop="mobile">
        <el-input v-model.trim="form.mobile"></el-input>
      </el-form-item>
      <el-form-item label="地址：" prop="address">
        <TMap ref="tmap" @change="mapChange"></TMap>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </template>
  </tj-dialog>
</template>

<script setup>
import { defaults } from 'lodash';
import { ref, computed, nextTick } from 'vue';
import { ElMessage } from 'element-plus';
import { useDialog } from '@tj/ui';
import request from '@/hooks/request';
import TMap from '@/components/TMap.vue';

const emit = defineEmits(['submit']);

const defaultSiteInfo = {
  id: 0,
  name: '',
  mobile: '',
  province: '',
  city: '',
  area: '',
  address: '',
  lat: 0,
  lng: 0,
};

const form = ref({ ...defaultSiteInfo });
const formRef = ref();

const rules = {
  name: { required: true, message: '网点名称不能为空' },
  mobile: { required: true, message: '联系方式不能为空' },
  address: { required: true, message: '地址不能为空' },
};

const title = computed(() => {
  if (form.value.id) {
    return '编辑网点';
  }
  return '添加网点';
});

const tmap = ref();

const {
  visible,
  open,
  close,
  onOpen,
} = useDialog();
let siteId = 0;
onOpen((id) => {
  siteId = id;
});

function onOpened() {
  if (siteId) {
    request.get(`/admin/site/serveSite/${siteId}`).then((data) => {
      form.value = data;
      if (data.lat && data.lng) {
        tmap.value.init(data.lat, data.lng);
      }
    });
  } else {
    form.value = { ...defaultSiteInfo };
    tmap.value.init();
  }
  nextTick(() => {
    formRef.value.clearValidate();
  });
}

function onClose() {
  form.value = { ...defaultSiteInfo };
}

function mapChange(map) {
  form.value = defaults(map, form.value);
}

function submit() {
  formRef.value.validate((valid) => {
    if (valid) {
      const { id, lat, lng } = form.value;
      if (lat && lng) {
        if (id) {
          request.patch(`/admin/site/serveSite/${id}`, form.value).then(() => {
            emit('submit');
            close();
          });
        } else {
          request.post('/admin/site/serveSite', form.value).then(() => {
            emit('submit');
            close();
          });
        }
      } else {
        ElMessage.error('请在地图中标记网点位置');
      }
    }
  });
}

defineExpose({
  open,
});
</script>
